import { SearchFilter } from "src/components/Filter/SearchFilter"
import { useTranslate } from "src/i18n/useTranslate"
import { DropdownButton } from "src/ui/Button/DropdownButton"
import { TMButtonProps } from "src/ui/Button/MButton"
import { TextButton } from "src/ui/Button/TextButton"
import {
  BoxBottom,
  EmptyBox,
  ErrorBox,
  Item,
  ItemsList,
  Option,
  StyledSearch,
  StyledSelect,
} from "src/ui/DropdownSelect/sharedStyles"
import { MBanner } from "src/ui/MBanner/MBanner"
import { MText, MTextProps } from "src/ui/MText"
import { Maybe } from "src/utils/tsUtil"

export type TSelectOption<
  V extends string,
  O extends { [key: string]: unknown } = Record<string, unknown>,
> = O & {
  value: V
  label: string
  disabled?: boolean
}

export function DropdownSelect<
  V extends string,
  O extends { [key: string]: unknown } = Record<string, unknown>,
>({
  label,
  options = [],
  selectedValue,
  onChange,
  searchText,
  onSearch,
  onClearSelection,
  debounceDelay = 400,
  mButtonVariant = "secondary",
  mTextVariant = "body",
  placeholder,
  size = "small",
  errorMsg,
}: {
  label: string
  options: TSelectOption<V, O>[]
  selectedValue: Maybe<TSelectOption<V>["value"]>
  onChange: ({ option }: { option: TSelectOption<V, O> }) => void
  searchText?: string
  onSearch?: (s: string) => void
  onClearSelection?: () => void
  debounceDelay?: number
  mButtonVariant?: TMButtonProps["variant"]
  mTextVariant?: MTextProps["variant"]
  placeholder?: string
  size?: TMButtonProps["size"]
  errorMsg?: React.ReactNode
}) {
  const { t, langKeys } = useTranslate()

  function handleOptionClick(
    option: TSelectOption<V, O>,
    closeMenu: (event?: React.MouseEvent<EventTarget>) => void
  ) {
    closeMenu()
    onChange({ option })
  }

  return (
    <DropdownButton
      displayValue={label}
      placement="bottom-start"
      color="default"
      variant={mButtonVariant}
      size={size}
    >
      {({ closeMenu, onClickAway }) => (
        <StyledSelect>
          {onSearch && (
            <StyledSearch>
              <SearchFilter
                onChange={(v) => {
                  onSearch(v)
                }}
                placeholder={placeholder}
                delay={debounceDelay}
                initialValue={searchText}
              />
            </StyledSearch>
          )}

          {options.length === 0 && (
            <EmptyBox>
              <MText variant={mTextVariant}>
                {t(langKeys.search_no_matches_found)}
              </MText>
            </EmptyBox>
          )}

          <ItemsList
            $showBorderTop={!!onSearch}
            $showBorderBottom={!!onClearSelection}
            onClick={onClickAway}
          >
            {options.map((option) => (
              <Option
                key={option.label}
                onClick={() => handleOptionClick(option, closeMenu)}
                tabIndex={-1}
                $selected={option.value === selectedValue}
              >
                <Item>
                  <MText variant={mTextVariant}>{option.label}</MText>
                </Item>
              </Option>
            ))}
          </ItemsList>

          {!!errorMsg && (
            <ErrorBox>
              <MBanner type="error" size="small">
                {errorMsg}
              </MBanner>
            </ErrorBox>
          )}

          {onClearSelection && (
            <BoxBottom>
              <TextButton
                onClick={onClearSelection}
                disabled={!onClearSelection || !selectedValue}
              >
                {t(langKeys.clear_selection)}
              </TextButton>
            </BoxBottom>
          )}
        </StyledSelect>
      )}
    </DropdownButton>
  )
}
